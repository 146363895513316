import merge from "lodash/merge";
import { createI18n } from "vue-i18n";

import { useI18nLocale } from "../composables/i18n-format.hook";

declare module "#app" {
  interface NuxtApp {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $i18n: any;
  }
}

const I18N_PREFERENCE_COOKIE_NAME = "i18n_locale";

// const readPreferredLocalesFromAcceptLanguageHeader = (value: string) => {
//   return value.split(",").map((entry) => entry.split(";")[0].split("-")[0]);
// };

const readPreferredLocaleFromCookieHeader = (value: string) => {
  return value.match(/i18n_locale=([a-z]{2})/)?.[1] ?? "";
};

const retrievePreferredLocale = (availableLocales: string[]) => {
  let locales: string[] = [];

  if (process.server) {
    const headers = useRequestHeaders(["cookie"]);

    const saved = readPreferredLocaleFromCookieHeader(headers.cookie ?? "");
    // let's deactivate inferred locale for now
    // const inferred = readPreferredLocalesFromAcceptLanguageHeader(
    //   headers["accept-language"] ?? "",
    // );

    locales = [
      saved,
      // ...inferred
    ];
  }

  if (process.client) {
    const saved = useCookie(I18N_PREFERENCE_COOKIE_NAME).value ?? "";
    // let's deactivate inferred locale for now
    // const inferred = navigator.languages.map(
    //   (language) => language.split("-")[0],
    // );

    locales = [
      saved,
      // ...inferred
    ];
  }

  return locales.find((locale) => availableLocales.includes(locale)) as
    | "fr"
    | "en"
    | undefined;
};

export default defineNuxtPlugin({
  enforce: "pre",
  setup: ({ vueApp }) => {
    const i18n = createI18n({
      legacy: false,
      globalInjection: true,
      locale: "fr",
      fallbackLocale: "fr",
      messages: {
        en: {},
        fr: {},
      },
    });

    const availableLocales = i18n.global.availableLocales;
    const preferredLocale = retrievePreferredLocale(availableLocales);

    if (preferredLocale) {
      i18n.global.locale.value = preferredLocale;
      useI18nLocale().value = preferredLocale;
    }

    vueApp.use(i18n);

    const setLocaleMessages = (
      locale: (typeof availableLocales)[number],
      messages: unknown,
    ) => {
      const existingMessages = unref(i18n.global.messages)[locale];
      i18n.global.setLocaleMessage(locale, merge(existingMessages, messages));
    };

    return {
      provide: {
        i18n,
        setLocaleMessages,
      },
    };
  },
});
