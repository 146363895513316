import type { OrganizationMemberView } from "~~/model/organization-member.model";

export const useCurrentOrganizationMember = () => {
  const member = useState<OrganizationMemberView | null | undefined>(
    "current_organization_member",
  );

  const client = useDatabaseClient();
  const { me } = useCurrentUser();

  const refresh = async () => {
    const { data } = await client
      .from("organization_members")
      .select("*, user: users(*)")
      .eq("user_id", me.value!.id)
      .single();

    member.value = data as OrganizationMemberView;

    return data;
  };

  const ensureIsLoaded = async () => {
    if (member.value !== undefined) return member.value;

    return refresh();
  };

  const isOwner = computed(() => member.value?.role === "OWNER");
  const isMember = computed(() => member.value?.role === "MEMBER");

  return {
    member: readonly(member),
    ensureIsLoaded,
    refresh,
    reset: () => (member.value = undefined),
    isOwner,
    isMember,
  };
};
