import type { DialogRegistry } from "@pollen/ui-library/client/shared/dialog/dialog-ui-library-registry";

const dialogRegistry = {
  OrganizationMemberDeleteDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/organization-member/components/organization-member-delete-dialog.vue"
      ),
  ),
  RegistrationRequestAcceptationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration-request/components/registration-request-acceptation-dialog.vue"
      ),
  ),
  RegistrationRequestRejectionDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration-request/components/registration-request-rejection-dialog.vue"
      ),
  ),
  RegistrationRequestAcceptationBlockedForMissingCreditsDialog:
    defineAsyncComponent(
      () =>
        import(
          "~/business-areas/registration-request/components/registration-request-missing-credits-dialog.vue"
        ),
    ),
  RatingDetailsDialog: defineAsyncComponent(
    () =>
      import("~/business-areas/rating/components/rating-details-dialog.vue"),
  ),
  MultiSessionBatchRegistrationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration/components/multi-session-registration-batch-dialog.vue"
      ),
  ),
  SessionBatchRegistrationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration/components/session-registration-batch-dialog.vue"
      ),
  ),
  SessionRegistrationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/registration/components/session-registration-dialog.vue"
      ),
  ),
  SessionInvitationCustomizationDialog: defineAsyncComponent(
    () =>
      import(
        "~/business-areas/session/components/session-invitation-customization-dialog.vue"
      ),
  ),
} satisfies DialogRegistry;

export { dialogRegistry };
