import { defineRule } from "vee-validate";

export default defineNuxtPlugin(() => {
  defineRule("required", (value: string, parameters, context) => {
    if (!value || (Array.isArray(value) && value.length === 0))
      return `Le champ ${context.field} est obligatoire`;

    return true;
  });

  defineRule("email", (value: string, parameters, context) => {
    if (!value || /^[\w!#$%&'*+./=?^`{|}~-]+@[\d.A-Za-z-]+$/gm.test(value))
      return true;

    return `${context.field} n'est pas un e-mail valide`;
  });

  defineRule("lte", (value: number, [max]: [number | undefined], context) => {
    if (max === undefined || value <= max) return true;

    return `${context.field} doit être inférieur ou égal à ${max}`;
  });

  defineRule("gte", (value: number, [min]: [number | undefined], context) => {
    if (min === undefined || value >= min) return true;

    return `${context.field} doit être supérieur ou égal à ${min}`;
  });
});
