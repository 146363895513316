import { createPopper } from "@popperjs/core";

const SMOOTH_SCROLL_OFFSET_IN_PX = 20;

function clickHandler(event: MouseEvent) {
  event.preventDefault();

  const href = (event.target as HTMLElement).closest("a")?.getAttribute("href");
  if (!href || !href.startsWith("#")) return;

  const element = document.querySelector<HTMLElement>(href);
  if (!element) return;

  const position = element.getBoundingClientRect();

  scrollTo({
    top:
      position.top +
      (document.scrollingElement?.scrollTop ?? 0) -
      SMOOTH_SCROLL_OFFSET_IN_PX,
    behavior: "smooth",
  });
}

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive("smooth-scroll", {
    mounted(el) {
      if (el.destroyAttachedDirective) return;

      function destroyAttachedDirective() {
        el.removeEventListener("click", clickHandler);
      }

      el.addEventListener("click", clickHandler);
      Object.defineProperty(el, "destroyAttachedDirective", {
        value: destroyAttachedDirective,
      });
    },
    unmounted(el) {
      el.destroyAttachedDirective?.();
    },
  });

  vueApp.directive("tooltip", {
    async mounted(el, { value }) {
      /*
      Avoid weird behaviour especially on modal transitions not being played when some tooltip are used in it.
       */
      await nextTick();

      if (!value || el.destroyTooltip) return;

      const tooltipText = typeof value === "string" ? value : value.value;

      const tooltip = document.createElement("p");
      tooltip.textContent = tooltipText;
      tooltip.classList.add(
        "bg-black/80",
        "text-white",
        "max-w-[280px]",
        "text-xs",
        "py-1",
        "px-2",
        "rounded",
        "hidden",
        "z-[9999]",
      );
      document.body.append(tooltip);

      const popperInstance = createPopper(el, tooltip, {
        placement: typeof value === "object" ? value.placement : "bottom",
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8],
            },
          },
          {
            name: "preventOverflow",
            options: {
              padding: 20,
            },
          },
        ],
      });

      const show = () => {
        tooltip.classList.remove("hidden");

        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...(options.modifiers ?? []),
            { name: "eventListeners", enabled: true },
          ],
        }));

        popperInstance.update();
      };

      const hide = () => {
        tooltip.classList.add("hidden");
        popperInstance.setOptions((options) => ({
          ...options,
          modifiers: [
            ...(options.modifiers ?? []),
            { name: "eventListeners", enabled: false },
          ],
        }));
      };

      const showEvents = ["mouseenter", "focus"];
      const hideEvents = ["mouseleave", "blur"];

      showEvents.forEach((event) => el.addEventListener(event, show));
      hideEvents.forEach((event) => el.addEventListener(event, hide));

      const destroyTooltip = () => {
        showEvents.forEach((event) => el.removeEventListener(event, show));
        hideEvents.forEach((event) => el.removeEventListener(event, hide));
        popperInstance.destroy();
      };

      Object.defineProperty(el, "destroyTooltip", {
        value: destroyTooltip,
      });
    },
    unmounted(el) {
      el.destroyTooltip?.();
    },
  });
});
