export const dialogUiLibraryRegistry = {
  ConfirmationDialog: defineAsyncComponent(
    () => import("./components/confirmation-dialog.vue"),
  ),
  PdfViewerDialog: defineAsyncComponent(
    () => import("../pdf/components/pdf-viewer-dialog.vue"),
  ),
} as const satisfies DialogRegistry;

export type DialogRegistry = Record<
  string,
  ReturnType<typeof defineAsyncComponent>
>;
