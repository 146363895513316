import type {
  FeatureFlagKey,
  FeatureFlagRegistry,
} from "../../model/feature-flag.model";

export function useFeatureFlagStore() {
  const innerState = useState<FeatureFlagRegistry>(
    "feature_flags",
    () => ({}) as FeatureFlagRegistry,
  );

  return {
    flags: readonly(innerState),
    set: (state: FeatureFlagRegistry) => {
      innerState.value = state;
    },
  };
}

export function useFeatureFlag(key: FeatureFlagKey) {
  const { flags } = useFeatureFlagStore();
  const { isAdmin } = useCurrentUser();
  const enabled = flags.value[key]?.enabled;
  const accessGranted = isAdmin.value || !flags.value[key]?.admin_only;

  return enabled && accessGranted;
}
