import { captureException, setUser } from "@sentry/browser";
import type { User } from "@supabase/supabase-js";

const identify = (user: User | null) => {
  if (!user?.id || !user?.email) return setUser(null);

  return setUser({ id: user.id, email: user.email });
};

export const useMonitoring = () => {
  return {
    identify,
    logError: (error: Error) => {
      if (import.meta.dev) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      captureException(error);
    },
  };
};
