import mitt from "mitt";

declare module "#app" {
  interface NuxtApp {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $emit(event: string | string[], payload?: any): void;
    $on(
      event: string | string[],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handler: (payload?: any) => any,
      options?: { clearOnUnmount?: boolean },
    ): void;
  }
}

export default defineNuxtPlugin(() => {
  const on = (
    event: string | string[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handler: (payload?: any) => void,
    options?: { clearOnUnmount?: boolean },
  ) => {
    const arrayifiedEvents = Array.isArray(event) ? event : [event];
    arrayifiedEvents.forEach((eventName) => bus.on(eventName, handler));

    if (options?.clearOnUnmount !== false) {
      onUnmounted(() => {
        arrayifiedEvents.map((eventName) => bus.off(eventName, handler));
      });
    }
  };

  const bus = mitt();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emit = (eventName: string, payload?: any) => {
    bus.emit(eventName, payload);
  };

  return {
    provide: {
      emit,
      on,
    },
  };
});
