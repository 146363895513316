import type { AnalyticsClient } from "@pollen/core/client/composables/analytics.hook";

import type { TrackingPlanModel } from "~/business-areas/analytics/analytics.model";

declare module "#app" {
  interface NuxtApp {
    $analytics: AnalyticsClient<TrackingPlanModel>;
  }
}

export default defineNuxtPlugin(() => {
  const {
    public: {
      segment: { apiKey },
    },
  } = useRuntimeConfig();

  const { organization } = useCurrentOrganization();

  return {
    provide: {
      analytics: getAnalyticsClient<TrackingPlanModel>(apiKey, {
        globalContext: computed(() => {
          return {
            organization: organization.value
              ? {
                  name: organization.value.name,
                  slug: organization.value.slug,
                }
              : undefined,
          };
        }),
      }),
    },
  };
});
