<template>
  <Html class="h-screen w-screen overflow-hidden">
    <Body>
      <nuxt-layout>
        <nuxt-page />
      </nuxt-layout>

      <app-toast-notification-handler />
      <app-dialog-handler :registry="dialogRegistry" />
    </Body>
  </Html>
</template>

<script lang="ts" setup>
import { dialogRegistry } from "~/shared/dialog/dialog.registry";

useSeoMeta({
  robots: "noindex",
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - Pollen Hub` : "Pollen Hub";
  },
});
</script>
