<template>
  <component
    :is="_registry[activeDialogId]"
    v-if="isOpen && activeDialogId"
    v-model="isOpen"
    v-bind="activeDialogBinding"
  />
</template>

<script lang="ts" setup>
import { dialogEventHook } from "../composables/dialog.hook";
import {
  type DialogRegistry,
  dialogUiLibraryRegistry,
} from "../dialog-ui-library-registry";

const properties = withDefaults(
  defineProps<{
    registry?: DialogRegistry;
  }>(),
  {
    registry: () => ({}) as DialogRegistry,
  },
);

const _registry = computed<DialogRegistry>(() => {
  return {
    ...properties.registry,
    ...dialogUiLibraryRegistry,
  };
});

const isOpen = ref(false);
const activeDialogId = ref<string | undefined>();
const activeDialogBinding = ref<Record<string, unknown> | undefined>();
const isSwitchingDialog = ref(false);

dialogEventHook.open.on(async (event) => {
  if (isOpen.value) {
    isSwitchingDialog.value = true;
    activeDialogId.value = undefined;
    isOpen.value = false;
    activeDialogBinding.value = undefined;

    await nextTick();
  }

  activeDialogId.value = event.id;
  activeDialogBinding.value = event.binding;
  isOpen.value = true;
  isSwitchingDialog.value = false;
});

dialogEventHook.close.on(() => {
  if (isSwitchingDialog.value) return;

  activeDialogId.value = undefined;
  isOpen.value = false;
  activeDialogBinding.value = undefined;
});

watch(isOpen, (value) => {
  if (!value) {
    activeDialogId.value = undefined;
    activeDialogBinding.value = undefined;
  }
});
</script>
