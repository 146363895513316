export default defineNuxtPlugin(async () => {
  const user = useSupabaseUser();

  if (user) {
    const {
      data: { user: serverUser },
    } = await useDatabaseClient().auth.getUser();

    if (!serverUser) {
      useMonitoring().logError(
        new Error("User set on cliebt but not on server"),
      );
    }
  }
});
