import type { User } from "@supabase/supabase-js";

export interface AppUser {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  avatarUrl?: string;
  identity: { provider: string };
  created_at: Date;
  last_signed_in_at: Date;
  is_first_connection: boolean;
}

function toFirstName(full_name: string) {
  return full_name.split(" ")[0] ?? "";
}

function toLastName(full_name: string) {
  return full_name.split(" ").slice(1).join(" ");
}

const toAppUser = (user: User): AppUser => {
  const created_at = new Date(user.created_at);
  const last_signed_in_at = new Date(user.last_sign_in_at ?? user.created_at);
  const is_first_connection =
    last_signed_in_at.getTime() - created_at.getTime() < 200;
  const full_name = user.user_metadata.full_name ?? (user.email as string);

  if (user.app_metadata.provider === "google") {
    return {
      id: user.id,
      full_name,
      first_name: toFirstName(full_name),
      last_name: toLastName(full_name),
      email: user.email as string,
      avatarUrl: user.user_metadata.avatar_url,
      identity: {
        provider: user.app_metadata.provider,
      },
      created_at,
      last_signed_in_at,
      is_first_connection,
    };
  }

  return {
    id: user.id,
    full_name,
    first_name: toFirstName(full_name),
    last_name: toLastName(full_name),
    email: user.email as string,
    identity: {
      provider: user.app_metadata.provider as string,
    },
    created_at,
    last_signed_in_at,
    is_first_connection,
  };
};

const logout = async (options?: { redirect?: string }) => {
  await useDatabaseClient().auth.signOut({ scope: "local" });
  return navigateTo(options?.redirect ?? "/");
};

export function useCurrentUser() {
  const supabaseUser = useSupabaseUser();

  const isAuthenticated = computed(() => !!supabaseUser.value);
  const isAdmin = computed(() => !!supabaseUser.value?.app_metadata.admin);

  const me = computed(() => {
    if (!supabaseUser.value) return;

    return toAppUser(supabaseUser.value);
  });

  return {
    isAuthenticated,
    isAdmin,
    me,
    logout,
  };
}
