export const useCurrentOrganization = (
  slug?: DatabaseTable<"organizations">["slug"],
) => {
  const organization = useState<
    | (DatabaseTable<"organizations"> & {
        packs: Pick<DatabaseTable<"organization_credit_packs">, "id">[];
      })
    | null
    | undefined
  >("current_organization");

  const client = useDatabaseClient();

  const refresh = async () => {
    let query = client
      .from("organizations")
      .select("*, packs: organization_credit_packs(id)");

    if (slug) {
      query = query.eq("slug", slug);
    }

    const { data } = await query;

    organization.value = data?.[0] ?? null;

    return organization.value;
  };

  const ensureIsLoaded = async () => {
    if (
      organization.value !== undefined &&
      (organization.value === null || !slug || organization.value.slug === slug)
    )
      return organization.value;

    return refresh();
  };

  return {
    organization: readonly(organization),
    ensureIsLoaded,
    hasNoOrganization: computed(() => organization.value === null),
    hasCreditPacks: computed(() => !!organization.value?.packs.length),
    refresh,
    reset: () => (organization.value = undefined),
  };
};
