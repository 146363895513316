import { browserTracingIntegration, init, setTag } from "@sentry/vue";
import type { Router } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { sentry },
  } = useRuntimeConfig();
  const currentUser = useSupabaseUser();
  const { identify } = useMonitoring();

  if (sentry.dsn) {
    init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      release: sentry.release,
      ignoreErrors: sentry.ignoreErrors,
      integrations: [
        browserTracingIntegration({
          router: useRouter() as Router,
          enableInp: true,
        }),
      ],
      tracesSampleRate: 0.5,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });
    setTag("layer", "client");
  }

  watch(
    currentUser,
    (user) => {
      identify(user);
    },
    { immediate: true },
  );
});
