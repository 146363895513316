export default defineNuxtPlugin({
  enforce: "pre",
  async setup() {
    const { set } = useFeatureFlagStore();

    try {
      const state = await $fetch("/api/feature-flags");
      set(state);
    } catch {
      /* empty */
    }
  },
});
