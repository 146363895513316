export const markAppAsLoaded = () => {
  /*
  Add an identifiable class to app body once the app is fully loaded on client side (after hydration)
  Usefull for e2e context to only interact with the page once it's fully ready
  */
  document.body.classList.add("app_loaded");
};

export default defineNuxtPlugin(() => {
  onNuxtReady(() => {
    markAppAsLoaded();
  });
});
